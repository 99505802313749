<template>
  <div class="assign-banker-item row w-100 justify-content-between my-1">
    <div class="d-flex">
      <avatar
        :src="$getFileURL(_.get(banker, 'avatar[0]', null))"
        :name="banker.name"
        :size="36"
        class="mr-2"
        :isLoading="isLoading"
      ></avatar>
      <div v-if="isLoading">
        <p
          class="skeleton mb-1"
          :style="{ height: '18px', width: '200px' }"
        ></p>
        <p
          class="skeleton mb-1"
          :style="{ height: '14px', width: '150px' }"
        ></p>
      </div>
      <div v-else>
        <p class="font-bold" :style="{ color: '#555', fontSize: '16px' }">
          {{ banker.name }}
        </p>
        <p :style="{ color: '#777' }">{{ banker.bank }}</p>
      </div>
    </div>
    <div v-if="isAssign || isRemove" class="d-flex align-items-center">
      <fd-button
        v-if="isAssign"
        class="ml-2 bordered main"
        @click="$emit('assign', banker)"
      >
        <i class="fas fa-plus"></i>
      </fd-button>
      <fd-button
        v-if="isRemove"
        class="ml-2 bordered danger"
        @click="$emit('remove', banker)"
      >
        <i class="fas fa-times"></i>
      </fd-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Avatar: () => import("@/components/GlobalComponents/Avatar")
  },
  mixins: [],
  props: {
    isAssign: {
      type: Boolean,
      default: false
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    banker: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.assign-banker-item {
  width: max-content;
}
</style>
